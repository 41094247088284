import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Sidebar from '../blocks/sidebar';
import Message from "../message";

import { navMap } from './helpers';

function NavLi(props) {
  const { canView, id, path, label, auth } = props;
//!Проверка роли и показ меню
  const allowed = !canView.length
    ? true
    : canView?.some((role) => auth.user.roles.some((user) => role === user.roleName));
  const className = ({ isActive }) => `${isActive ? 'active' : ''}`;
  let userRole = auth.user.roles[0].roleName;
  //console.log(userRole);

  if (userRole === "admin-role" || userRole === 'analytic-role') {
    return (
      <li key={id}>
        <NavLink to={path} className={className}>
          {label}
        </NavLink>
      </li>
    );
  }
  //console.log('Test');
    if (userRole === 'customer-role') {
      if (canView.includes("customer-role", 0)) {

        return (
          <li key={id}>
            <NavLink to={path} className={className}>
              {label}
            </NavLink>
          </li>
        );
      }
    } else if (userRole === "start-role") {
      if (canView.includes("start-role", 0)) {

        return (
          <li key={id}>
            <NavLink to={path} className={className}>
              {label}
            </NavLink>
          </li>
        );

      } else if (canView.includes('customer-role', 0)) {
        console.log(canView.includes('customer-role', 0))
        //console.log('111',label)
        return (
          <li key={id}>
            <div>
                <Message
                  buttonName = {{label}}
                  content1 = "Раздел доступен для пользователей с ролью “Профессионал”."
                  content2 = "За дополнительной информацией обратитесь к администаторам АИС “РискЛаб”."
                />
            </div>
          </li>
        );
      }


  }


  //!


}

export default function Nav() {
  const auth = useSelector((state) => state.authReducer);

  return (
    <Sidebar>
      <div className="menu-navbar">
        <ul className="list-unstyled">
          {navMap.map((el, index) => (
            <NavLi {...el} key={`${el.id}-${index}`} auth={auth} />
          ))}
        </ul>
      </div>
    </Sidebar>
  );
}
