import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import * as Cells from '../../../blocks/tableCells';
import RemoveButtonWithConfirm from '../../../removeButtonWithConfirm';
import * as Fields from '../fields';

let role = true;
const ProjectsTable = ({ loading, data, onChangeTable, onPostProjectEdit, onPostProjectDelete }) => {
  if(useSelector((state) => state.authReducer.user.roles[0].roleName) === 'start-role'){
      role = false;
    }
  const columns = [
    {
      key: 'data',
      title: 'Дата',
      align: 'center',
      sorter: true,
      render: (record) => <Cells.Date data={record?.data} />,
    },
    {
      key: 'name',
      title: 'Наименование',
      align: 'center',
      sorter: true,
      render: (record) => (
        role ? <Fields.EditRowsAction id={record.id} onPostProjectEdit={onPostProjectEdit}>
          {record.name}
        </Fields.EditRowsAction> : record.name
      ),
    },
    {
      key: 'inn',
      title: 'ИНН',
      align: 'center',
      sorter: true,
      render: (record) => record.inn,
    },
    {
      key: 'pm',
      title: 'Количество РМ',
      align: 'center',
      sorter: true,
      render: (record) => record.pm,
    },
    {
      key: 'employee',
      title: 'Количество работников',
      align: 'center',
      sorter: true,
      render: (record) => record.employee,
    },
    {
      key: 'grade',
      title: 'Не оценено/В работе/Завершено',
      align: 'center',
      render: (record) => {
        const grade = [record.not_rated ?? 0, record.in_work ?? 0, record.completed ?? 0].join('/');
        return <Fields.Grade align={'center'} label={grade} />;
      },
    },
    {
      title: '',
      render: (record) => (
      role ? <RemoveButtonWithConfirm itemId={record.id} onClick={onPostProjectDelete} /> : ""),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      dataSource={data?.data}
      onChange={onChangeTable}
      pagination={{
        defaultPageSize: 50,
        current: data?.current_page,
        total: data?.total,
      }}
    />
  );
};

export default ProjectsTable;
