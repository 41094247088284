import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import * as hooks from '../../../hooks';
import InputSearch from '../../atoms/search';
import { FormikProvider } from '../../formik/formik.context';
import HeaderGrade from '../../Menu/MenuRating/HeaderGrade';
import Message from "../../message";

import { usePostProjectEdit } from './fields/editRow/hooks';
import * as Fields from './fields';
import { usePostProject } from './hooks';
import * as Styled from './styled';
import ProjectsTable from './table';
import { projectYup } from './valide.yup';

const { useBoolean } = hooks;
let role = true;

export default function Grade() {
  const { loading, formik, data, onRefetchProjects, onChangeTable, onPostProjectDelete } = usePostProject();
  const showNewOrganization = useBoolean(false);

  const formikGrade = useFormik({
    enableReinitialize: true,
    validationSchema: projectYup,
    validateOnBlur: true,
    validateOnChange: true,
    initialTouched: {
      project: {
        inn: true,
        shortName: true,
      },
    },
    initialValues: {
      project: {
        inn: null,
        kpp: null,
        fullName: null,
        shortName: null,
        okogu: null,
        ogrn: null,
        okpo: null,
        oktmo: null,
        okved: null,
        postalCode: null,
        regionName: null,
        districtName: null,
        cityName: null,
        innerCityName: null,
        localityName: null,
        streetName: null,
        house: null,
        building: null,
        chiefJobTitle: null,
        chiefFullName: null,
      },
      isChief: {},
      commission: [],
      expert: [],
    },
  });

  const { respDataProjectEdit, onPostProjectEdit } = usePostProjectEdit(formikGrade);
  role = true;
  if(useSelector((state) => state.authReducer.user.roles[0].roleName) === 'start-role'){
    role = false;
  }
  function mes(){
    console.log('qqq');
    alert("Test");
   return (
      <div>
                <Message
                  buttonName = "Создать проект"
                  content1 = "Раздел доступен для пользователей с ролью “Профессионал”."
                  content2 = "За дополнительной информацией обратитесь к администаторам АИС “РискЛаб”."
                />
                </div>
            );
  }


  const onCreateNewProject = () => {
    const [_, onShow] = showNewOrganization;
    formikGrade.resetForm();
    formikGrade.setStatus('new');
    formikGrade.validateForm();
    onShow.on();
  };
let label = "Создать проект";
  const handleChangeSearchProject = (event) => {
    formik.setFieldValue('search', event.target.value);
  };

  return (
    <FormikProvider formik={formikGrade}>
      <HeaderGrade />
      <Styled.Section>
        <Styled.SectionHead jContent={'space-between'} widthItem={[465, 170]}>
          <InputSearch placeholder={'Поиск'} onChange={handleChangeSearchProject} />
          {role ?
          <Styled.ButtonCreate variant={'primary'} onClick={onCreateNewProject}>
            Создать проект
          </Styled.ButtonCreate> :
           <Styled.ButtonCreate variant={'primary'}>
           <Message
                  buttonName = {{label}}
                  content1 = "Раздел доступен для пользователей с ролью “Профессионал”."
                  content2 = "За дополнительной информацией обратитесь к администаторам АИС “РискЛаб”."
                />
         </Styled.ButtonCreate>
         }
        </Styled.SectionHead>
        <ProjectsTable
          loading={loading}
          data={data}
          onChangeTable={onChangeTable}
          onPostProjectEdit={onPostProjectEdit}
          onPostProjectDelete={onPostProjectDelete}
        />
        <Fields.EditRow
          id={formikGrade.values.project?.id}
          respDataProjectEdit={respDataProjectEdit}
          isShowInfoOrganization={showNewOrganization}
          onPostProject={onRefetchProjects}
        />
      </Styled.Section>
    </FormikProvider>
  );
}
